.login-form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("../../images/login.jpg");
  background-size: cover;
}
.form-wrapper {
  background: rgb(0 0 0 / 55%);
  width: 700px;
  padding: 64px;
  border-radius: 3px;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 75%);
  align-items: center;
  display: flex;
  flex-direction: column;
}
.form-item label {
  color: white;
  width: 300px;
}
.login-logo {
  width: 200px;
}
